export default {
  otherProjects: {
    title: '', 
    subtitle: 'Development Project',
    list: [
      {id: 'The ALPS', name: 'The ALPS', image: require('@/assets/images/property-dev/alps/1.jpg') },
      {id: 'Poiz', name: 'Poiz', image: require('@/assets/images/property-dev/poiz/2.jpg') },
      {id: 'One Canberra', name: 'One Canberra', image: require('@/assets/images/property-dev/one-canberra/4.jpg') },
      {id: 'Canberra Residences', name: 'Canberra Residences', image: require('@/assets/images/property-dev/canberra-residences/3.jpg') },
      {id: 'The Canopy', name: 'The Canopy', image: require('@/assets/images/property-dev/one-canopy/2.jpg') },
    ]
  },
  projectMag: {
    title: '',
    subtitle: 'Management Project',
    list: [
      {id: 'The Nautical', name: 'The Nautical', image: require('@/assets/images/property-dev/nautical/1.jpg')},
      {id: 'Forestville', name: 'Forestville', image: require('@/assets/images/property-dev/forestville/1.jpg')},
      {id: 'Sea Horizon', name: 'Sea Horizon', image: require('@/assets/images/property-dev/sea-horizon/1.jpg')},
      {id: 'Queens Peak', name: 'Queens Peak', image: require('@/assets/images/property-dev/queens-peak/1.jpg')},
      {id: 'NorthWave', name: 'NorthWave', image: require('@/assets/images/property-dev/north-wave/1.jpg')},
    ]
  },
  onSalesProjects: [
    {
      title: 'On Sale Project',
      subtitle: 'Singapore',
      projects: [
        {
          id: 'Sceneca',
          name: 'SCENECA RESIDENCE & SCENECA SQUARE', 
          location: 'Singapore', 
          desc: `
          Sceneca Residence & Sceneca Square , A premium mixed-use development with total land area 8,880 sqm development in District 16  Linked directly to Tanah Merah MRT Interchange, Sceneca Residence gives residents easy connectivity and convenience at their doorstep.
          `,
          images: [
            require("@/assets/images/property-dev/sceneca/sceneca_1.png"),
            require("@/assets/images/property-dev/sceneca/sceneca_2.png"),
            require("@/assets/images/property-dev/sceneca/sceneca_3.png"),
            require("@/assets/images/property-dev/sceneca/sceneca_4.png"),
            require("@/assets/images/property-dev/sceneca/sceneca_5.png"),
            require("@/assets/images/property-dev/sceneca/sceneca_6.png"),
            // require("@/assets/images/property-dev/sceneca/sceneca_7.png"),
            // require("@/assets/images/property-dev/sceneca/sceneca_8.png"),
            // require("@/assets/images/property-dev/sceneca/sceneca_9.png"),
            // require("@/assets/images/property-dev/sceneca/sceneca_10.png")
          ]
        },
        {
          id: 'One Bernam',
          name: 'One Bernam', 
          location: 'Singapore', 
          desc: 'One Bernam is served by the Tanjong Pagar MRT Station and Prince Edward Road MRT Station, as well as the Ayer Rajah Expressway (AYE). Within reach are Cantonment Primary School and the National Library, while VivoCity is just a short drive away. One Bernam also enjoys proximity to Marina Bay Sands and Great World City. The project commenced work in 2019 with completion slated for 2023.',
          images: [
            // require('@/assets/images/property-dev/one-bernam/1.JPG'), 
            require('@/assets/images/property-dev/one-bernam/ClubhouseNite-6K-Fn4.gif'),
            require('@/assets/images/property-dev/one-bernam/L34_FunctionRoom-6K-3.gif'),
            require('@/assets/images/property-dev/one-bernam/2.gif'), 
            require('@/assets/images/property-dev/one-bernam/Aerial02-6K-ExtFn1.gif'),
            require('@/assets/images/property-dev/one-bernam/ClubhouseINTDay-6K-Fn3.gif'),
            require('@/assets/images/property-dev/one-bernam/Hammock-8K-Fn2.gif'),
            require('@/assets/images/property-dev/one-bernam/Hero02-6K-Fn2.gif'),
            require('@/assets/images/property-dev/one-bernam/Pool1-8k-Fn5.gif'),
           /*  require('@/assets/images/property-dev/one-bernam/PoolCabana-8K-Fn4.jpg'),
            require('@/assets/images/property-dev/one-bernam/Retail-6K-Fn1.jpg'),
            require('@/assets/images/property-dev/one-bernam/Spa-8K-Fn3.jpg'),
            require('@/assets/images/property-dev/one-bernam/Tatami-8k-Fn3.jpg'), */
          ]
        },
        {
          id: 'Provence Residence',
          name: 'Provence Residence', 
          location: 'Singapore', 
          desc: 'Provence Residence is inspired by the effortless sophistication and elegance of French design. Various shopping malls like the Sun Plaza and Sembawang Shopping Centre also offer convenient options for lifestyle, dining and entertainment. Provence Residence commenced work in 2020 with completion slated for 2026.',
          images: [
            /* require('@/assets/images/property-dev/provence-residence/6Z2A0107.JPG'), 
            require('@/assets/images/property-dev/provence-residence/6Z2A0131.JPG'),
            require('@/assets/images/property-dev/provence-residence/6Z2A0148.JPG'), */
            require('@/assets/images/property-dev/provence-residence/PR6.gif'),
            require('@/assets/images/property-dev/provence-residence/PR1.gif'),
            require('@/assets/images/property-dev/provence-residence/PR2.gif'), 
            require('@/assets/images/property-dev/provence-residence/PR3.gif'),
            require('@/assets/images/property-dev/provence-residence/PR4.gif'),
            require('@/assets/images/property-dev/provence-residence/PR5.gif'),
          ]
        },
        {
          id: 'Landmark',
          name: 'Landmark', 
          location: 'Singapore', 
          desc: 'Located along Chin Swee Road, The Landmark enjoys proximity to the Outram Park MRT Station and Chinatown MRT Station. Within walking distance is the Robertson Quay, and River Valley Primary School is also a short drive away',
          images: [
            require('@/assets/images/property-dev/landmark/7.gif'),
            require('@/assets/images/property-dev/landmark/6.gif'), 
            // require('@/assets/images/property-dev/landmark/11.gif'),
            require('@/assets/images/property-dev/landmark/1.gif'), 
            require('@/assets/images/property-dev/landmark/2.gif'),
            require('@/assets/images/property-dev/landmark/4.gif'),
            require('@/assets/images/property-dev/landmark/5.gif'),
            require('@/assets/images/property-dev/landmark/8.gif'),
            require('@/assets/images/property-dev/landmark/9.gif'),
            require('@/assets/images/property-dev/landmark/10.gif'), 
          ]
        },
      ]
    },
    {
      title: 'On Sale Project',
      subtitle: 'Southeast Asia',
      projects: [
        {
          id: 'Sky Villa',
          name: 'Sky Villa',
          location: 'Phnom Penh, Cambodia', 
          desc: 'The project has a total construction area of 120,000 square metres. Sky Villa comprises two 35-storey towers of luxury residential apartments in the prime district of central Phnom Penh. The property features 256 units ideal for personal or multi-generational family living with generous residential spaces that range from 272 square metres to 509 square metres. ', 
          images: [
            require('@/assets/images/property-dev/sky-vila/1.jpg'),
            require('@/assets/images/property-dev/sky-vila/2.jpg'),
            require('@/assets/images/property-dev/sky-vila/3.jpg'),
            require('@/assets/images/property-dev/sky-vila/4.jpg'),
          ]
        },
        {
          id: 'JKT Living Star',
          name: 'JKT Living Star', 
          location: 'Jakarta', 
          desc: 'The core location of the Southeast Jakarta area with urban facilities and convenient living. JKT Living Star also enjoys proximity to the upcoming Jakarta Light Rail Transit (LRT) as well as the Ciracas and Cibubur Station. The project commenced work in 2019 and was completed in 2021.', 
          images: [
            require('@/assets/images/property-dev/living-star/6.gif'),
            require('@/assets/images/property-dev/living-star/1.gif'),
            require('@/assets/images/property-dev/living-star/2.gif'),
            // require('@/assets/images/property-dev/living-star/3.jpg'),
            require('@/assets/images/property-dev/living-star/4.gif'),
            require('@/assets/images/property-dev/living-star/5.gif'),
            // require('@/assets/images/property-dev/living-star/7.jpg'),
            require('@/assets/images/property-dev/living-star/8.gif'),
            // require('@/assets/images/property-dev/living-star/9.jpg'),
            require('@/assets/images/property-dev/living-star/10.gif'),
          ]
        },
      ]
    },
  ]
}